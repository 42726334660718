#Loading {
	.overlay {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0, 0, 0, 0.7);
		visibility: hidden;
		opacity: 0;
		z-index: 10000;
	}

	.overlay-show {
		visibility: visible;
		opacity: 1;
	}
	.overlay-hide {
		display: block;
	}

	.popup {
		margin: 70px auto;
		padding: 20px;
		background: #fff;
		border-radius: 5px;
		width: 50%;
		position: relative;
		transition: all 5s ease-in-out;
		max-height: 60%;
	}
	#genericPopUp {
		margin-top: 150px;
	}

	.popup h4 {
		margin-top: 0;
		color: #fff;
		font-family: 'zekton' !important;
		background: #0066ff;
		padding: 10px;
	}

	.popup.error h4 {
		background: red;
	}

	.popus .error {
		height: 150px !important;
	}

	.popup .close {
		position: absolute;
		top: 25px;
		right: 30px;
		transition: all 200ms;
		font-size: 30px;
		font-weight: bold;
		text-decoration: none;
		color: #fff;
	}
	.popup .close:hover {
		color: #fff;
	}
	.popup .content {
		max-height: 300px;
		overflow-x: hidden;
		overflow-y: scroll;
	}

	@media screen and (max-width: 700px) {
		.popup {
			width: 70%;
		}
	}

	/* .loader
	  -----------------------------------------------------------------------------------------*/
	.loader {
		height: 40px;
		left: 50%;
		margin: -20px 0 0 -20px;
		overflow: hidden;
		position: absolute;
		right: 0;
		top: 50%;
		width: 40px;
		z-index: 1999;
	}

	.loader-load {
		background: #000000 url(../../../assets/images/icons/loader.gif) no-repeat 50% 50%;
		-webkit-border-radius: 5px;
		-khtml-border-radius: 5px;
		-moz-border-radius: 5px;
		-ms-border-radius: 5px;
		-o-border-radius: 5px;
		border-radius: 5px;
		cursor: pointer;
		height: 40px;
		left: 50%;
		margin: -20px 0 0 -20px;
		-webkit-opacity: 0.8;
		-khtml-opacity: 0.8;
		-moz-opacity: 0.8;
		-ms-opacity: 0.8;
		-o-opacity: 0.8;
		-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
		filter: alpha(opacity=80);
		opacity: 0.8;
		overflow: hidden;
		position: absolute;
		top: 50%;
		width: 40px;
		z-index: 1000;
	}

	.progress-loader {
		left: 40%;
		margin: -20px 0 0 -20px;
		position: absolute;
		top: 50%;
		width: 24%;
		z-index: 1000;
	}

	.progress {
		border-radius: 5px;
		height: 0.3rem;
	}

	.progress-bar {
		background-color: #3c8dbc;
	}
}
