.sinRegistros{
	font-size: 2rem;
	font-weight: bold;
	color: #545558;
	font-family:Verdana, Geneva, sans-serif !important;
	:hover{
		background-color: #f0f0f0;
	}
}
.sinRegistros h5{
	font-size: 1.2rem;
	font-family:Verdana, Geneva, sans-serif !important;
	:hover{
		background-color: #f0f0f0;
	}
}

.table-fixed {
	overflow: auto;
	max-height: 65vh;
	padding-left: 0.1px;
	padding-right: 0.1px;
}


.table-fixed > table > thead > tr > th {
	position: -webkit-sticky;
	position: sticky;
	top: 0px;
	z-index: 1;
	vertical-align: bottom;
	background-color: #fff;
	border: none;
	white-space: nowrap;
	/*
	box-shadow: 0px 2px 0px #1d568f;
	border: 1px solid #0093BA;
	*/
	border-top: none !important;
    border-bottom: none !important;
    box-shadow: inset 0px 0px 0px 1px #3ab9db;
	background-clip: padding-box;
}

.table-fixed table tbody:nth-of-type(1) tr:nth-of-type(1) td {
	border-top: none !important;
}

.table-fixed > table > tfoot > tr > td {
	position: sticky;
	bottom: 0px;
	z-index: 1;
	vertical-align: bottom;
	background-color: #e00e0e;
	border: none;
	box-shadow: 0px 2px 0px #dee2e6;
	white-space: nowrap;
	border: 1.1px solid #0093BA;
	
}

.table-fixed > table {
	margin-bottom: 0 !important;
	
}

/*.table-fixed > tbody > th {
    position: sticky;
    left: 0;
}*/

.table-fixed > table {
	border-collapse: collapse;
	width: 100%;
}

.table-fixed > table > tbody > tr > td,
.table-fixed > table > thead > tr > th,
.table-fixed > table > tfoot > tr > td {
	word-break: inherit;
}

.table-guias th {
	text-align: center;
	font-weight: normal !important;
	color: #ffffff !important;
	background: #3c8dbc !important;
	background-color: #3c8dbc !important;
	padding: 10px;
	
}

.table-guias td {
	word-break: normal !important;
}

.table-guias tbody tr:nth-child(even) {
	background: white !important;
}

.table-guias tbody tr:nth-child(odd) {
	/*background: #ccc;*/
	background: #eee !important;
}

.table-desktop.table-bordered {
	border: 0 !important;
}

.table-transparent th {
	background-color: #b6c3c7 !important;
	color: rgb(230, 230, 230) !important;
	font-weight: bold !important;
}

.table-desktop {
	th {
		background-color: #ecf0f5 !important;
		text-align: center;
		border: 0 !important;
		border-bottom-color: #367fa9 !important;
		border-bottom-style: solid !important;
		border-bottom-width: 1px !important;
	}

	tbody tr td {
		border: 0;
		text-align: center;
		border-right-color: #367fa9 !important;
		border-right-style: solid !important;
		border-right-width: 1px !important;
	}

	tbody tr td:last-child {
		border-right-width: 0 !important;
		border-left-color: #367fa9 !important;
		border-left-style: solid !important;
		border-left-width: 1px !important;
	}
}

.table th {
	padding: 0.75rem;
}

.table td {
	padding: 0.50rem 0.75rem;
    vertical-align: middle;
}

.table-admin th {
	background-color: #00387A !important;
	font-weight: bold !important;
	border: 1.1px solid #0093BA;
	
}

.table-admin td {
	vertical-align: middle !important;
}

.tablaContador {
	width: 100%;
	text-align: left;
}

.thead-desktop th {
	background-color:#3c8dbc;
	font-size: 16px;
	border: 1px solid rgba(60, 141, 188, 0.3)
}

.tbody-desktop tr:nth-child(odd){
	background-color: #d8e8f2!important;
}
.tbody-desktop td {
	border: 1px solid rgba(60, 141, 188, 0.3)
}