#Default {
	.container-default {
		background-color: #333;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		color: #fff;
		//max-width: 2000px;
	}

	.container-default a {
		color: #fff;
	}

	.cc {
		position: relative;
		margin-left: 30px;
		font-size: 15px;
		transition: all 0.5s;
	}

	.cc:after {
		position: absolute;
		bottom: 7px;
		left: 0px;
		width: 100%;
		height: 4px;
		background-color: #0066ff;
		content: '';
		opacity: 0;
		transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
		transform: translateY(40px);
	}

	.cc:hover:after {
		opacity: 1;
		transform: translateY(15px);
	}

	#banner-default {
		background-color: #000;
		top: 0;
		position: fixed;
		width: 100%;
		z-index: 99999;
	}

	#banner-default a:active,
	#banner-default a:hover {
		outline-width: 0;
	}

	#banner-default a:hover {
		color: #0066ff;
	}

	#inicio {
		max-width: 900px;
		margin-bottom: 50px;
		padding: 64px 0px;
		margin: auto;
	}

	#inicio > div {
		margin-top: 70px;
	}

	#inicio h2 {
		font-size: 2rem;
	}

	#inicio p:nth-of-type(1) {
		font-size: 1.2rem;
	}

	#inicio p:nth-of-type(2) {
		font-size: 1.4rem;
	}

	#servicio {
		padding-top: 65px;
		width: 100%;
		float: left;
		background: linear-gradient(to bottom right, black, #333333);
	}

	#servicio > div {
		max-width: 1100px;
		padding: 64px 0px;
		margin: auto;
	}

	.servicios:hover {
		background: -moz-linear-gradient(top, rgba(0, 102, 255, 0.74) 0%, rgba(0, 102, 255, 0.38) 100%);
		background: -webkit-linear-gradient(top, rgba(0, 102, 255, 0.74) 0%, rgba(0, 102, 255, 0.38) 100%);
		background: linear-gradient(to bottom, rgba(0, 102, 255, 0.74) 0%, rgba(0, 102, 255, 0.38) 100%);
		border-radius: 4px;
		-moz-border-radius: 4px;
		-webkit-border-radius: 4px;
	}

	.servicios img {
		width: 100%;
		padding: 50px 50px 25px 50px;
		float: left;
	}

	.servicios .text-image {
		cursor: pointer;
	}

	.servicios .text-image:hover {
		color: #0066ff;
	}

	#brochure {
		width: 100%;
		float: left;
		background-color: #666666;
	}

	#brochure > div {
		max-width: 900px;
		padding: 60px 5%;
		margin: auto;
		margin-top: 64px;
		margin-bottom: 25px;
	}

	#brochure p {
		width: 100%;
		float: left;
		text-decoration: none;
		font-family: 'zekton';
		color: #fff;
		font-size: 20px;
		line-height: 21px;
		margin-top: 1em;
	}

	#contacto {
		width: 100%;
		float: left;
		background-color: #333333;
	}

	#contacto > div {
		max-width: 900px;
		padding: 60px 5%;
		margin: auto;
		margin-top: 110px;
	}

	#receptorias {
		text-align: center;
		width: 100%;
		float: left;
		color: #000;
		background: #ccc;
	}

	#receptorias .title {
		font-size: 28px;
		margin-bottom: 0;
	}

	#receptorias .subtitle {
		font-size: 1.2rem;
		margin-bottom: 0;
	}

	#receptorias > div:nth-of-type(1) {
		max-width: 900px;
		padding: 60px 5%;
		margin: auto;
	}

	#map {
		filter: grayscale(75%);
	}

	#footer-default {
		width: 100%;
		padding: 5px 0px;
		float: left;
		display: flex;
		justify-content: center;
		background-color: #000;
	}

	#footer-default img {
		width: 80px;
		float: left;
	}

	input,
	textarea {
		background: transparent;
		color: #fff;
		border: 1px solid #ccc;
		padding: 8px;
		display: block;
		width: 100%;
	}

	.carousel {
		margin-top: 95px;
	}

	.dropdown {
		position: relative;
		display: inline-block;
		cursor: pointer;
	}

	.dropdown > button {
		background: transparent;
		border: none;
		padding: 0 0 0 0;
		transition: all 0.5s;
		position: relative;
		color: #fff;
		text-decoration: none;
		font-family: 'zekton', sans-serif;
		margin-left: 30px;
		font-size: 15px;
	}

	.dropdown > div {
		transition: all 0.5s;
		display: none;
		position: absolute;
		min-width: 200px;
		background-color: #000;
	}

	.dropdown:hover > div {
		display: block;
	}

	.dropdown > div > a {
		width: 100%;
		display: block;
		padding: 8px 16px;
		border: none;
		white-space: normal;
		float: none;
		outline: 0;
		overflow: hidden;
		cursor: pointer;
		text-decoration: none;
	}

	.dropdown > div:hover {
		//box-shadow: 0 4px 10px 0 rgb(0 0 0 / 20%), 0 4px 20px 0 rgb(0 0 0 / 19%);
	}

	svg.logoc {
		position: absolute;
		width: 33%;
		margin-left: 23.5%;
		margin-top: 8.8%;
		z-index: 1;
		fill: #fff;
	}

	.css-319lph-ValueContainer {
		flex: initial
	}

	.css-1hb7zxy-IndicatorsContainer {
		-webkit-box-align: center;
		align-items: center;
		align-self: stretch;
		display: flex !important;
		flex-shrink: 0;
		box-sizing: border-box;
	}

	.css-319lph-ValueContainer {
		flex: 1 1 0% !important;
	}

	.gm-style .gm-style-iw-c {
		top: -45px;
	}

	.gm-style .gm-style-iw-t::after {
		top: -45px;
	}
}

.popup-productos {
	.modal-content, .modal-header, .modal-body {
		background-color: #000 !important;
	}

	.modal-body {
		color: #fff;
	}

	.modal-title {
		width: 100%;
		text-align: center;
		font-size: 30px;
		color: #0066ff;
	}

	.modal-content {
		padding: 0;
	}

	.modal-footer button {
		color: #fff;
		background-color: #0066ff;
	}
}
