#NotFound {
	height: 100vh;

	div.contenedor {
		width: 100%;
		margin: 0 auto;
		background: #000;
		background-image: url('../../images/fondowebd.png');
		color: #fff;
		font-size: 14px;
		text-align: center;
		height: 100%;
	}

	.errorMessage {
		background-color: #000;;
		padding: 6vw 8vw;
		border: 1px solid #fff;
		border-style: groove;
		border-width: 2px;
		border-color: #0066ff;
		border-radius: 3px;
	}

	.errorMessage h1 {
		font-size: 8em;
	}

	.errorMessage button {
		font-size: 20px;
		color: #0066ff;
	}
}
