#FormularioAU {
	background-color: #000;
	height: 100%;
	margin: 0;
	padding: 0;

	.row {
		margin: 0;
	}

	label {
		color: #fff !important;
	}

    input {
        background: transparent!important;
        border-color: #0066ff!important;
        border-top-color: transparent!important;
        border-left-color: transparent!important;
        border-right-color: transparent!important;
        margin-top: 10px!important;
        padding:5px 5px 5px 5px!important;
        color: #fff!important;
        width: 100%!important;
        font-size: 16px!important;
        border-width: 1px;
    }

	table {
		margin: auto;
	}

	table th {
		background-color: #06f;
	}
}

@media (min-width: 768px) {
	#FormularioAU {
		height: 100vh;
	}

	#FormularioAU > div {
		margin-left: 70px;
	}
}