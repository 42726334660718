@import './components/fileInput';
@import './components/banner';
@import './components/bannerAdmin';
@import './components/card';
@import './components/checkbox';
@import './components/container';
@import './components/containerAdmin';
@import './components/datePicker';
@import './components/leftMenu';
@import './components/loading';
@import './components/modal';
@import './components/select';
@import './components/table';
@import './components/pagination';

@import './pages/changePassword';
@import './pages/clienteDesktop';
@import './pages/default';
@import './pages/login';
@import './pages/notFound';
@import './pages/operadorDesktop';
@import './pages/seguimiento';
@import './pages/viewFamilias';
@import './pages/digitalizar';
@import './pages/procesarCarpeta';
@import './pages/generarGuia';
@import './pages/facturacion';
@import './pages/formularioAU';
@import './pages/bunge';
@import './pages/pymoa';
@import './pages/generadorDeUsuarios';

@font-face {
	font-family: 'zekton';
	src: url(../../assets/fonts/zekton.ttf) format('truetype');
}


.swal2-popup {
	display: none;
    position: relative;
    box-sizing: border-box;
    grid-template-columns: minmax(0,100%);
    width: 32em;
    max-width: 100%;
    padding: 0 0 0em;
    border: none;
    border-radius: 5px;
    background: rgb(26, 202, 70);
    color: #545454;
    font-family: inherit;
    font-size: 1rem;
}

* {
	color: inherit;
}

body {
	line-height: initial;
	font-family: 'zekton';
	background-color: #ecf0f5;
	font-size: 16px;
}

.text-justify {
	text-align: justify;
}

hr {
	height: 4px;
	background-color: #0066ff;
	opacity: 1;
	margin: 0;
	float: left;
	width: 100%;
}

button,
input,
optgroup,
select,
textarea {
	margin: initial;
	font-size: initial;
	line-height: initial;
}

::-webkit-scrollbar {
	width: 15px;
	height: 10px;
}

::-webkit-scrollbar-thumb {
	background: #999999;
}

::-webkit-scrollbar-track {
	background: #cccccc;
}

.btn {
	color: #fff;
	padding: 6px 12px;
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.42857143;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-image: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: 'zekton';
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

@media (min-width: 992px) {
	.modal-lg {
		width: 80vw !important;
	}
}

.select2-container .select2-selection--single {
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	height: 34px !important;
	user-select: none;
	-webkit-user-select: none;
}

td a {
	cursor: pointer;
	text-decoration: underline #72afd2 !important;
}

.table-striped tbody tr:nth-child(even) {
	background-color: #fff !important;
}

.table-striped tbody tr:nth-child(odd) {
	background-color: #ccc !important;
}

.btn:hover {
	//-webkit-box-shadow: inset 0 0 100px rgb(0 0 0 / 20%);
	//box-shadow: inset 0 0 100px rgb(0 0 0 / 20%);
	color: #fff;
}

.inner {
	border-radius: 4px;
	margin-top: 10px;
	text-align: center;
	font-size: 18px;
	background: #0073b7;
	border-color: #0073b7;
	padding: 15px 0px 10px 0px;
	width: 100%;
	margin-bottom: 10px;
	color: #fff;
}

.inner-success {
	background: forestgreen;
	border-color: forestgreen;
}

.inner-warning {
	background: #f39c12;
	border-color: #f39c12;
}

.inner-danger {
	background: #dd4b39;
	border-color: #dd4b39;
}

.form-group label {
	font-weight: 700 !important;
}

.textarea-guia {
	border-radius: 1px;
	resize: none;
	outline-color: #000000 !important;
	-webkit-border-radius: 1px;
	-moz-border-radius: 1px;
	-ms-border-radius: 1px;
	-o-border-radius: 1px;
	margin: 10px;
}

.input-guia {
	width: 70%;
	border-radius: 4px;
	resize: none;
	outline-color: #0066ff !important;
	padding: 5px;
	border: 1.5px solid #f39c12;
}

.swal2-html-container { 
	margin: 0 !important;
}

.swal2-popup {
	padding: 0 !important;
}

.btn {
	transition: none !important;
}

.modal-90w {
    width: 90%;
    max-width: none!important;
}

textarea {
	resize: none;
}

.btn-link {
	color: #007bff;
}

.btn-link:hover {
	color: #4995e7;
}

div.dropdown-menu a[href] {
	text-decoration: none !important;
}

select {
	-webkit-appearance: auto !important;
    appearance: auto !important;
}

.cursor-pointer {
	cursor: pointer;
}

.btn-secondary[disabled] {
	cursor: not-allowed;
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}