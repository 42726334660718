#ClienteSeguimiento {
	.logo-andreani {
		max-height: 20px;
		width: auto;
		float: right;
		filter: brightness(0) invert(100%);
	}

	.tracking-searchbox {
		padding: 2.5px 7.5px !important;
		border-radius: 4px !important;
		border: #3c8dbc solid 1px;
		margin-left: 15px;
	}
}
