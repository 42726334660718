#Container {
	.content-wrapper {
		padding-left: 30px;
		padding-right: 30px;
		margin-top: 85px;
		min-height: 900px;
		background-color: #ecf0f5;
		z-index: 800;
	}
}
