#OperadorDesktop {
	.td-align-center {
		text-align: center;
	}

	#tablaGuias {
		width: 100%;
	}

	#tablaLegajos {
		width: 100%;
	}

	#modalTablaGuias {
		width: 100%;
	}

	.text-center {
		border-left-color: #367fa9;
		border-left-width: 1px;
		border-left-style: solid;
	}

	.text-center1 {
		text-align: center;
	}

	.text-center,
	.text-center1 {
		padding-top: 10px;
	}

	.tr-head {
		border-bottom-color: #367fa9;
		border-bottom-style: solid;
		border-bottom-width: 1px;
		padding-bottom: 10px;
		margin-bottom: 15px;
	}

	.td-align-center {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.borderGuiaCorreo {
		background-color: 2px solid #367fa9;
	}

	#containerGuias .table-fixed {
		max-height: initial !important;
	}
}
