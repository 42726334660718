#Banner {
	header.main-header img {
		width: 200px;
		margin-top: 8px;
		margin-bottom: 1px;
		margin-left: 15px;
	}

	header.main-header {
		position: fixed;
		height: 85px;
		width: 100%;
		background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 2%, rgba(0, 0, 0, 0.93) 99%, rgba(0, 0, 0, 0.93) 100%) !important;
		background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 2%, rgba(0, 0, 0, 0.93) 99%, rgba(0, 0, 0, 0.93) 100%) !important;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 2%, rgba(0, 0, 0, 0.93) 99%, rgba(0, 0, 0, 0.93) 100%) !important;
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bf000000', endColorstr='#ed000000',GradientType=0 );
		z-index: 1030;
	}

	#box-agente {
		z-index: 99999;
		float: right;
		color: rgb(255, 255, 255);
		margin-right: 20px;
		position: fixed;
		right: 0px;
		top: 22px;
		text-decoration: none;
		padding: 10px;
		border-color: rgb(255, 255, 255);
		border-style: solid;
		border-width: 1px;
		border-radius: 5px;
	}

	#box-agente div:nth-of-type(1) {
		float: left;
		margin-right: 10px;
	}

	#box-agente div:nth-of-type(2) {
		float: left;
		width: 25px;
	}

	#span-agente-status-online {
		min-width: 24px;
		width: 100%;
		background-color: green;
		height: 20px;
		float: left;
		border-radius: 5px;
	}

	#span-agente-status-offline {
		min-width: 19px;
		width: 100%;
		background-color: red;
		height: 20px;
		float: left;
		border-radius: 5px;
	}

	#box-agente #span-agente-status-online i {
		margin-left: 7px;
	}

	#box-agente #span-agente-status-offline i {
		margin-left: 4px;
	}
}
