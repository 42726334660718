.familias {
	padding: 0px 10px;
}

.familias li {
	list-style-type: none;
}



.familias button {
	background: #007bff;
	border-color:#007bff ;
	padding: 5px;
	margin-top: 5px;
	border-radius: 5px;
	cursor: pointer;
	transition: all 0.5s;
	width: 100%;
	text-align: center;
}

.familias button, .familias a {
	color: #fff;
	font-weight: bold;
	line-height: 1.5;
}

.familias button:hover {
    background: #1865a5;
}

#leftMenuFamilias {
	display: flex;
    flex-direction: column;
    height: 100vh;
	overflow-y: auto;
	overflow-x: hidden;
}

/*.w3-third img{
    margin-bottom: -6px;
    opacity: 0.8;
    cursor: pointer;
}

.w3-third img:hover{
    opacity: 1;
}

.sindeco {
    background: #2196f3;
    list-style-type: none;
    color: #fff;
    padding: 5px;
    margin-top: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.5s;
}
 .sindeco:hover {
    background: #1865a5;
}
 .containercheq {
    display: block;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-left: 150px;
}
 .containercheq input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    float: left;
}
 .checkmark {
    margin-top: 0px;
    position: absolute;
    height: 25px;
    width: 25px;
    border-style: solid;
    border-width: 2px;
    border-color: #3c8dbc;
    float: left;
}
 .containercheq:hover input ~ .checkmark {
    background-color: #ccc;
}
 .containercheq input:checked ~ .checkmark {
    background-color: #3c8dbc;
}
 .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
 .containercheq input:checked ~ .checkmark:after {
    display: block;
}
 .containercheq .checkmark:after {
    left: 6px;
    top: 0px;
    width: 9px;
    height: 18px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
 .cheq-label{
    float: left;
    font-weight: 600;
    margin-left: 35px;
    margin-top: 3px;
    font-size: 14px;
}
 .checkbox label, .radio label {
    padding-left: 0px !important;
}
 .not-active {
    cursor: not-allowed;
    background-color: gray !important;
}*/