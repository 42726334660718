.file-select {
	position: relative;
	display: inline-block;
	
}

.file-select::before {
	background-color: #f8f6f6;
	color: rgb(3, 3, 3);
	font-family: 'zekton';
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	content: 'Seleccionar'; /* testo por defecto */
	position: absolute;
	left: 20px;
	right: 0;
	top: 22px;
	bottom: 0;
	border: solid 1px rgb(219, 219, 219);
}
.file-select:hover::before {
	border: solid 1px rgb(182, 179, 179);
	background-color: #e2e2e2;
}

.file-select input[type="file"] {
	opacity: 0;
	width: 240px;
	height: 60px;
	
	display: inline-block;
}

#src-file1::before {
	
	content: 'Seleccione el Archivo ...';
}
