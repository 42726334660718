#BannerAdmin {
	@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

	.navbar-brand img {
		width: 200px;
	}

	.navbar {
		background-color: #00387a !important;
		background: #00387a !important;
		background-image: initial !important;
		background-position-x: initial !important;
		background-position-y: initial !important;
		background-size: initial !important;
		background-attachment: initial !important;
		background-origin: initial !important;
		background-clip: initial !important;
		background-color: rgb(0, 56, 122) !important;
		position: fixed;
		height: 85px;
		width: 100%;
		z-index: 1030;
		top: 0;
		font-family: 'Varela Round', sans-serif;
	}

	.navbar .nav-link,
	.navbar .nav-item {
		color: #fff;
		text-transform: uppercase;
		font-size: 13px;
		margin: auto;
	}

	.navbar .dropdown-item {
		padding: 5px 7px 5px 7px;
		font-family: 'Varela Round', sans-serif;
		font-size: 13px;
		color: #00387a !important;
		text-transform: uppercase;
		-webkit-transition-duration: 0.2s;
		-moz-transition-duration: 0.2s;
		-o-transition-duration: 0.2s;
		transition-duration: 0.2s;
		-webkit-transition-property: all;
		-moz-transition-property: all;
		-o-transition-property: all;
		transition-property: all;
		-webkit-transition-timing-function: ease;
		-moz-transition-timing-function: ease;
		-o-transition-timing-function: ease;
		transition-timing-function: ease;
	}

	#lblUsuario,
	#lblCliente {
		font-family: 'zekton', sans-serif !important;
	}
}
