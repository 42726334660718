#ContainerAdmin {
	background-color: #fff;
	min-height: 100vh;

	.content-wrapper {
		margin-top: 85px;
		min-height: 900px;
		z-index: 800;
		background-color: #fff;
		margin-left: 0;
		padding-left: 30px;
		padding-right: 30px;
	}
}
