#AccountLogin {
	@import url('https://fonts.googleapis.com/css?family=Jura:400,600');

	background-color: #000;

	.container-login {
		font-family: 'Jura';
		color: #fff;
		//max-width: 2000px;
		background-color: #000;
		height: 100vh;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	#banner-login {
		background-color: #000;
		top: 0;
		position: fixed;
		width: 100%;
		z-index: 99999;
	}

	#banner-login a:active,
	#banner-login a:hover {
		outline-width: 0;
	}

	#banner-login a:hover {
		color: #0066ff;
	}

	.container-login a {
		color: #fff;
	}

	#login {
		max-width: 480px;
		padding: 64px 5%;
		margin: auto;
		margin-top: 70px;
	}

	.digex-control {
		background: transparent;
		border-color: #0066ff;
		border-top-color: transparent;
		border-left-color: transparent;
		border-right-color: transparent;
		margin-top: 10px;
		padding: 5px 5px 5px 5px;
		color: #fff;
		width: 100%;
		font-size: 16px;
		border-width: 1px;
	}

	.digex-control:hover {
		border-color: #6699ff;
	}

	#footer-login {
		position: fixed;
		bottom: 0;
		width: 100%;
		padding: 5px 0px;
		float: left;
		display: block;
		justify-content: center;
		background-color: #000;
		line-height: initial;
	}

	#footer-login img {
		width: 80px;
		float: left;
	}

	#footer-login > div {
		display: flex;
		justify-content: center;
		margin-top: 10px;
	}

	.btn-primary {
		padding: 8px 12px 8px 12px !important;
		background: #0066ff !important;
		color: #fff !important;
		border: 0;
		font-size: 14px;
		border-radius: 3px;
	}
}

.popup-forgot {
	.modal-content {
		padding: 0;
		font-size: 11px;
	}

	.modal-header {
		background-color: transparent;
		color: #000;
	}

	.modal-header .close {
		color: #000;
	}

	.modal-footer {
		text-align: right;
		border-top: solid 1px #e4e4e4;
		background-color: #f4f4f4;
		color: #000;
		padding: 0;
	}

	.modal-footer button {
		margin: 0;
		padding: 15px 20px;
		background-color: transparent;
		font-weight: normal;
		border: none;
		border-left: solid 1px #e4e4e4;
		color: #777;
		font-weight: bold;
		font-size: 12px;
	}

	.modal-footer button[type='submit'] {
		color: #489afe;
	}
}

#loginClaveExpirada, #loginRecuperarClave {
	background-color: #000;
	margin-top: 70px;
	padding: 50px;
	padding-bottom: 100px;

	.digex-control {
		color: '#fff';
		width: '100%';
		height: "30px";
		background: 'transparent';
		border-top-color: 'transparent';
		border-left-color: 'transparent';
		border-right-color: 'transparent';
		border-width: 1;
		border-bottom-color: '#0066ff',
	}

	.disable {
		opacity: 0.5;
	}
	.enable {
		opacity: 1;
	}
}

@media (max-width: 767px){
	#loginClaveExpirada, #loginRecuperarClave{
		padding-bottom: 150px;
	}
}