.bgAzulZYSProcesar {
	background-color: #3c8dbc !important;
	color:white;
	padding: 12px;
	margin-top: 10px;
    margin-bottom: 10px;
	font-family: 'Lato',sans-serif;
	font-weight: 500;
}

.listaArchivos {
	max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.listaArchivos > div {
	padding: 10px;
	font-size: .9rem;
	cursor: pointer;
}

.listaArchivos > div:nth-of-type(odd){
	background: #eee;
}

.listaArchivos > div:nth-of-type(even){
	background: #ccc;
}

.list-group-item-action:focus, .list-group-item-action:hover {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #adc4ff !important;
}

.bgBlue5ProcesarPdf {
	background: rgb(115, 180, 211);
	padding: 10px;
	font-size: 12px;
	margin-top: 0px;
    margin-bottom: 10px;
}

h6 {
	font-size: 0.85rem;
}

.gear {
	background-color: #67B8DE;
	padding: 10px;
	cursor: pointer;
}

.gear i {
	color: #fff;
}

.gear:hover {
	background-color: rgb(146, 146, 146);
}

.delete {
	background-color: #00387A;
	padding: 10px;
	cursor: pointer;
}

.delete i {
	color: #fff;
}

.digitalizarTitulo {
	margin-top: 0;
    color: #fff;
    font-family: 'zekton'!important;
    background: #0066ff;
    padding: 10px;
}

.digitalizarContadores {
	overflow-x: auto;
    overflow-y: hidden;
}

.digitalizarContadores li {
    background-color: #3c8dbc;
    color: white;
    font-weight: 700;
	border: 1px solid #3ab9db;
}
