.left-menu {
	position: fixed !important;
	padding-top: 0px;
	font-size: 14px;
}

.left-menu #toggle-menu {
	position: fixed;
	width: 23px !important;
	color: #000 !important;
	background: #f9fafc !important;
	margin-left: 229px !important;
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
	border-left-color: transparent !important;
	padding: 5px !important;
	padding-left: 2px !important;
	margin-top: 10px !important;
	border-right-color: #d2d6de !important;
	border-top-color: #d2d6de !important;
	border-bottom-color: #d2d6de !important;
	z-index: 9999999999;
	font-size: 14px;
}

.sidebar {
	display: flex;
    flex-direction: column;
    height: 100vh;
}

#ulMenuList {
	overflow-x: hidden;
	flex: 1 1 auto;
  	overflow-y: auto;
	margin-bottom: 75px;
	padding-bottom: 25px;
}

#ulMenuList::-webkit-scrollbar {
	width: 7px;
}

.left-menu a {
	text-decoration: none;
}

.pull-left {
	float: left;
}

.sidebar-menu > li > button {
	color: #0066ff !important;
	padding: 12px 5px 12px 15px !important;
	font-size: inherit !important;
	width: 100%;
	border-left: 3px solid transparent !important;
	font-weight: 400 !important;
	text-align: left !important;
	border-radius: 0 !important;
}

.sidebar-menu > li > button > .fa,
.sidebar-menu > li > button > .glyphicon,
.sidebar-menu > li > button > .ion {
	width: 20px;
}

.sidebar-menu li > button > .fa-angle-left,
.sidebar-menu li > button > .pull-right-container > .fa-angle-left {
	width: auto;
	height: auto;
	padding: 0;
	margin-right: 10px;
	margin-top: 2px;
	transition: transform 0.5s ease;
}

.skin-black-light .sidebar-menu > li:hover > button,
.skin-black-light .sidebar-menu > li.active > button {
	color: #fff !important;
	background: #0066ff !important;
}

.main-sidebar {
	left: 0;
	margin-top: 85px;
	min-height: 100%;
}

.skin-black-light .sidebar-menu > li > a {
	color: #0066ff;
	border-left: 3px solid transparent;
	font-weight: 400 !important;
}

.skin-black-light .sidebar-menu > li:hover > a,
.skin-black-light .sidebar-menu > li.active > a {
	color: #fff !important;
	background: #0066ff !important;
}

.sidebar-menu li > button > .fa-angle-left,
.sidebar-menu li > button i.dropdown-rotate {
	margin-top: 4px;
	transform: rotate(-90deg);
}

.menu-collapse {
	transform: translate(-230px, 0);
}

.control-sidebar-bg,
.control-sidebar {
	top: 0;
	right: -230px;
	width: 230px;
	transition: 0.3s ease-in-out;
	transition-property: all;
	transition-duration: 0.3s;
	transition-timing-function: ease-in-out;
	transition-delay: 0s;
}

.treeview-menu {
	height: auto;
	overflow: auto !important;
	display: block !important;
	-webkit-transition-timing-function: ease;
	-o-transition-timing-function: ease;
	transition-timing-function: ease;
	-webkit-transition-duration: 0.65s;
	-o-transition-duration: 0.65s;
	transition-duration: 0.65s;
	-webkit-transition-property: max-height, visibility;
	-o-transition-property: max-height, visibility;
	transition-property: max-height, visibility;
}

.treeview-menu-expanded {
	max-height: 300px !important;
}

.treeview-menu-collapsed {
	max-height: 0 !important;
}

.skin-black-light .sidebar-menu .treeview-menu > li > a:hover {
	color: #0066ff !important;
}

@media (max-width: 767px){
	.main-sidebar {
		transform: initial !important;
	}

	.sidebar-collapse .main-sidebar {
		transform: translate(-230px, 0) !important;
	}
}

.user-panel {
	flex: 0 0 auto;
}