#ClienteDesktop {
	.dropinfobox:hover > .infobox {
		display: block;
	}

	.dropinfobox:hover {
		border-bottom-style: solid !important;
		border-bottom-width: 1px !important;
		border-right-style: solid !important;
		border-right-width: 1px !important;
	}
	.dropinfobox-warnging:hover {
		border-bottom-color: #f39c12 !important;
		border-right-color: #f39c12 !important;
	}

	.dropinfobox-desp:hover {
		border-bottom-color: #f3c612 !important;
		border-right-color: #f3c612 !important;
	}

	.infobox {
		position: absolute;
		width: 80%;
		background: #f8f8f8;
		display: none;
		z-index: 99999999999999999999999;
		right: -1px;
		line-height: 17px;
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
		border-top-width: 0px !important;
		text-align: justify;
	}

	.infobox-scroll {
		overflow-y: scroll;
		max-height: 150px;
	}

	div.box-body-row {
		position: relative;
		display: block;
		padding: 10px 15px;
		border-radius: 0;
		border-top: 0;
		border-left: 3px solid transparent;
		color: #444;
	}

	div.box-body-row > span.badge {
		min-width: 22;
		text-align: 'center';
		border-radius: 4px;
	}

	div.box-body-row > span.badge-expanded {
		padding: 4.5px 7.5px;
	}
}
