.card {
	border: 1px solid #3c8dbc;

	.card-header {
		color: #fff;
		background: #3c8dbc;
		background-color: #3c8dbc;
		display: block;
		padding: 13px;
		position: relative;
		font-size: 18px;
		margin: 0;
		line-height: 1;
	}

	.card-header button.btn-card-collapse {
		width: 100% !important;
		background-color: #0073b7 !important;
		border-color: #0066ff !important;
		border-radius: 3px !important;
	}

	.card-header .box-tools {
		position: absolute;
		right: 10px;
		top: 5px;
	}

	.open .btn-box-tool,
	.btn-box-tool:hover {
		color: #fff;
	}

	.card-body {
		padding: 10px 15px;
	}
}

