
.form-control {
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


.form-control[type=file] {
    overflow: hidden;
}

.form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer;
}

::-webkit-file-upload-button{
	font:inherit
}

::file-selector-button{
	font:inherit
}

::-webkit-file-upload-button{
	font:inherit;
	-webkit-appearance:button
}

.form-control[type=file]{
    overflow:hidden
}
.form-control[type=file]:not(:disabled):not([readonly]){
    cursor:pointer
}
.form-control:focus{
    color:#212529;
    background-color:#fff;
    border-color:#86b7fe;
    outline:0;
    box-shadow:0 0 0 .25rem rgba(13,110,253,.25)
}
.form-control::-webkit-date-and-time-value{
    height:1.5em
}
.form-control::-moz-placeholder{
    color:#6c757d;
    opacity:1
}
.form-control::placeholder{
    color:#6c757d;
    opacity:1
}
.form-control:disabled,.form-control[readonly]{
    background-color:#e9ecef;
    opacity:1
}
.form-control::-webkit-file-upload-button{
    padding:.375rem .75rem;
    margin:-.375rem -.75rem;
    -webkit-margin-end:.75rem;
    margin-inline-end:.75rem;
    color:#212529;
    background-color:#e9ecef;
    pointer-events:none;
    border-color:inherit;
    border-style:solid;
    border-width:0;
    border-inline-end-width:1px;
    border-radius:0;
    -webkit-transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}
.form-control::file-selector-button{
    padding:.375rem .75rem;
    margin:-.375rem -.75rem;
    -webkit-margin-end:.75rem;
    margin-inline-end:.75rem;
    color:#212529;
    background-color:#e9ecef;
    pointer-events:none;
    border-color:inherit;
    border-style:solid;
    border-width:0;
    border-inline-end-width:1px;
    border-radius:0;
    transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}
@media (prefers-reduced-motion:reduce){
    .form-control::-webkit-file-upload-button{
        -webkit-transition:none;
        transition:none
    }
    .form-control::file-selector-button{
        transition:none
    }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button{
    background-color:#dde0e3
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button{
    background-color:#dde0e3
}
.form-control::-webkit-file-upload-button{
    padding:.375rem .75rem;
    margin:-.375rem -.75rem;
    -webkit-margin-end:.75rem;
    margin-inline-end:.75rem;
    color:#212529;
    background-color:#e9ecef;
    pointer-events:none;
    border-color:inherit;
    border-style:solid;
    border-width:0;
    border-inline-end-width:1px;
    border-radius:0;
    -webkit-transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}
@media (prefers-reduced-motion:reduce){
    .form-control::-webkit-file-upload-button{
        -webkit-transition:none;
        transition:none
    }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button{
    background-color:#dde0e3
}
.form-control-plaintext{
    display:block;
    width:100%;
    padding:.375rem 0;
    margin-bottom:0;
    line-height:1.5;
    color:#212529;
    background-color:transparent;
    border:solid transparent;
    border-width:1px 0
}
.form-control-plaintext.form-control-lg,.form-control-plaintext.form-control-sm{
    padding-right:0;
    padding-left:0
}
.form-control-sm{
    min-height:calc(1.5em + .5rem + 2px);
    padding:.25rem .5rem;
    font-size:.875rem;
    border-radius:.2rem
}
.form-control-sm::-webkit-file-upload-button{
    padding:.25rem .5rem;
    margin:-.25rem -.5rem;
    -webkit-margin-end:.5rem;
    margin-inline-end:.5rem
}
.form-control-sm::file-selector-button{
    padding:.25rem .5rem;
    margin:-.25rem -.5rem;
    -webkit-margin-end:.5rem;
    margin-inline-end:.5rem
}
.form-control-sm::-webkit-file-upload-button{
    padding:.25rem .5rem;
    margin:-.25rem -.5rem;
    -webkit-margin-end:.5rem;
    margin-inline-end:.5rem
}
.form-control-lg{
    min-height:calc(1.5em + 1rem + 2px);
    padding:.5rem 1rem;
    font-size:1.25rem;
    border-radius:.3rem
}
.form-control-lg::-webkit-file-upload-button{
    padding:.5rem 1rem;
    margin:-.5rem -1rem;
    -webkit-margin-end:1rem;
    margin-inline-end:1rem
}
.form-control-lg::file-selector-button{
    padding:.5rem 1rem;
    margin:-.5rem -1rem;
    -webkit-margin-end:1rem;
    margin-inline-end:1rem
}
.form-control-lg::-webkit-file-upload-button{
    padding:.5rem 1rem;
    margin:-.5rem -1rem;
    -webkit-margin-end:1rem;
    margin-inline-end:1rem
}
