#OperadorProcesarCarpeta {
	.bgCeleste1 {
		background: #91C3D9 !important;
	}
	
	.bgCeleste3 {
		background: #80ACBF !important;
	}
	
	.bgGris4 {
		background: #ccc;    
	}

	.btn-primary.disabled, .btn-primary:disabled {
		color: #fff;
		background-color: #3c8dbc;
		border-color: #367fa9;
	}

	.btn.disabled, .btn:disabled {
		opacity: .5;
		cursor: auto;
	}
}
